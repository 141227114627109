import React, { useRef, useState, useMemo, useEffect } from 'react';
import cn from 'classnames';
import Explainer from '../blocks';
import ThirdPartyLogo from '@components/ThirdPartyLogo';
import staticFormats from '@content/static-formats.json';
import staticIntegrations from '@content/static-integrations.json';

import { useInView } from 'framer-motion';

import * as styles from './UniversalSupport.module.css';

const EXPLAINER_WIDTH = 628;
const EXPLAINER_HEIGHT = 340;
const MARQUEE_ITEM_WIDTH = 200;
const MARQUEE_ITEM_HEIGHT = 140;
const MARQUEE_BASE_SPEED = 1.5;
const ITEM_SPACING = 24;

const createPath = (width, height, radius) => {
  if (radius * 2 > width || radius * 2 > height) {
    radius = Math.min(width / 2, height / 2);
  }

  return `
    M${radius} 0.375
    H${width - radius}
    C${width - radius * 0.55} 0.375 ${width - 0.375} ${radius * 0.55} ${
    width - 0.375
  } ${radius}
    V${height - radius}
    C${width - 0.375} ${height - radius * 0.55} ${width - radius * 0.55} ${
    height - 0.375
  } ${width - radius} ${height - 0.375}
    H${radius}
    C${radius * 0.55} ${height - 0.375} 0.375 ${height - radius * 0.55} 0.375 ${
    height - radius
  }
    V${radius}
    C0.375 ${radius * 0.55} ${radius * 0.55} 0.375 ${radius} 0.375
    Z
  `;
};

const SVGCardWithLogo = ({ title, logo }) => {
  const xPadding = 24;
  const yPadding = 33;
  const logoHeight = 24;
  const logoWidth = 60;

  return (
    <g>
      <path
        d={createPath(MARQUEE_ITEM_WIDTH, MARQUEE_ITEM_HEIGHT, 6)}
        fill="var(--color-background-card)"
        stroke="none"
      />
      {logo && (
        <svg
          x={xPadding}
          y={MARQUEE_ITEM_HEIGHT - logoHeight - yPadding * 0.5}
          width={logoWidth}
          height={logoHeight}
          viewBox={`0 0 ${logoWidth} ${logoHeight}`}
        >
          <ThirdPartyLogo logoKey={logo} className={styles.logo} />
        </svg>
      )}
      <text
        x={xPadding}
        y={yPadding}
        fontFamily="var(--font-family-headline)"
        fill="var(--color-text-default)"
      >
        {title}
      </text>
    </g>
  );
};

const SVGMarquee = ({
  children,
  speed = 1,
  reverse = false,
  shouldPlay = true,
  onlyPlayInView = true,
  style,
  onPointerEnter,
  onPointerLeave,
}) => {
  const repeatCount = 2;
  const ref = useRef();
  const isInView = useInView(ref, { amount: 'some' });
  const itemCount = React.Children.count(children);
  const totalIterationWidth = itemCount * (MARQUEE_ITEM_WIDTH + ITEM_SPACING);

  const animationDuration = useMemo(
    () => ((totalIterationWidth / speed) * repeatCount) / MARQUEE_ITEM_WIDTH,
    [speed, repeatCount, totalIterationWidth],
  );

  useEffect(() => {
    if (!onlyPlayInView) {
      ref.current.classList.add(styles.inView);
      return;
    }
    if (isInView && shouldPlay) {
      ref.current.classList.add(styles.inView);
    } else {
      ref.current.classList.remove(styles.inView);
    }
  }, [isInView, shouldPlay, onlyPlayInView]);

  useEffect(() => {
    if (!shouldPlay) {
      ref.current.classList.add(styles.paused);
    } else {
      ref.current.classList.remove(styles.paused);
    }
  }, [shouldPlay]);

  const Inner = (i = 0) => {
    return React.Children.map(children, (child, j) => {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          key={`${i}-${j}`}
          className={styles.item}
          aria-hidden={i > 0 ? true : null}
          style={{ overflow: 'visible' }}
          filterUnits="userSpaceOnUse"
          x={(j + i * itemCount) * (MARQUEE_ITEM_WIDTH + ITEM_SPACING)}
          y="0"
        >
          <defs>
            <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
              <feDropShadow
                dx="0"
                dy="8"
                stdDeviation="5"
                floodColor="#FF6183"
                floodOpacity="0.15"
              />
            </filter>
          </defs>
          {child}
        </svg>
      );
    });
  };

  return (
    <g
      ref={ref}
      className={cn(styles.svgMarquee, { [styles.reverse]: reverse })}
      style={{
        ...style,
        '--marquee-speed': `${animationDuration}s`,
        '--marquee-item-width': `${MARQUEE_ITEM_WIDTH}px`,
        '--marquee-item-height': `${MARQUEE_ITEM_HEIGHT}px`,
        '--iteration-width': `${totalIterationWidth}px`,
      }}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      transform={`translate(0, ${reverse ? 176 : 20})`}
    >
      {isInView || !onlyPlayInView ? (
        <g className={styles.itemWrapper}>
          {Array.from({ length: repeatCount }).map((_, i) => Inner(i))}
        </g>
      ) : (
        <g className={styles.itemWrapper}>{Inner()}</g>
      )}
    </g>
  );
};

const SVGCardMarquee = ({ formats = [], integrations = [] }) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const combined = [...formats, ...integrations];
  const totalItems = combined.length;

  const speed = useMemo(() => {
    const computedSpeed = MARQUEE_BASE_SPEED / totalItems;
    return Math.max(0.0125, computedSpeed);
  }, [totalItems]);

  if (totalItems === 0) {
    return null;
  }

  return (
    <g
      onPointerEnter={() => setIsPlaying(false)}
      onPointerLeave={() => setIsPlaying(true)}
    >
      <SVGMarquee speed={speed} shouldPlay={isPlaying} onlyPlayInView={false}>
        {combined.map((card) => (
          <g className={styles.card} key={card.metadata.logoId}>
            <SVGCardWithLogo
              title={card.metadata.name}
              logo={card.metadata.logoId}
            />
          </g>
        ))}
      </SVGMarquee>
      <SVGMarquee
        reverse
        speed={speed}
        shouldPlay={isPlaying}
        onlyPlayInView={false}
      >
        {[...combined].reverse().map((card) => (
          <g className={cn(styles.card)} key={card.metadata.logoId}>
            <SVGCardWithLogo
              title={card.metadata.name}
              logo={card.metadata.logoId}
            />
          </g>
        ))}
      </SVGMarquee>
    </g>
  );
};

const UniversalSupport = () => {
  return (
    <Explainer
      width={EXPLAINER_WIDTH}
      height={EXPLAINER_HEIGHT}
      className={cn(styles.root)}
    >
      <SVGCardMarquee
        formats={staticFormats.formats}
        integrations={staticIntegrations.integrations}
      />
    </Explainer>
  );
};

export default UniversalSupport;
