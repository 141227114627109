import Explainer from '../blocks';

const EXPLAINER_WIDTH = 411;
const EXPLAINER_HEIGHT = 280;

import styles from './Analytics.module.css';

const Analytics = () => {
  return (
    <Explainer
      width={EXPLAINER_WIDTH}
      height={EXPLAINER_HEIGHT}
      className={styles.root}
    >
      <text x="57.7962" y="282.62" fontSize="12px">
        0MB
      </text>
      <g className={styles.line}>
        <line x1="90.6641" y1="232" x2="496.664" y2="232" stroke="#C4F4E0" />
        <text x="46.4266" y="232.5">
          100MB
        </text>
      </g>
      <g className={styles.line}>
        <line x1="90.6641" y1="181" x2="496.664" y2="181" stroke="#C4F4E0" />
        <text x="42.6735" y="181.5">
          200MB
        </text>
      </g>
      <g className={styles.line}>
        <line x1="90.6641" y1="131" x2="496.664" y2="131" stroke="#C4F4E0" />
        <text x="44.9665" y="131.62">
          300MB
        </text>
      </g>
      <g className={styles.line}>
        <line x1="90.6641" y1="81" x2="496.664" y2="81" stroke="#C4F4E0" />
        <text x="45.9613" y="81.5">
          400MB
        </text>
      </g>
      <g className={styles.line}>
        <line x1="90.6641" y1="31" x2="496.664" y2="31" stroke="#C4F4E0" />
        <text x="44.9802" y="31.62">
          500MB
        </text>
      </g>
      <rect
        x="113.664"
        y="160.5"
        width="15"
        height="121"
        className={styles.bar}
      />
      <rect
        x="142.664"
        y="160.5"
        width="15"
        height="121"
        className={styles.bar}
      />
      <rect
        x="171.664"
        y="180.5"
        width="15"
        height="101"
        className={styles.bar}
      />
      <rect
        x="199.664"
        y="160.5"
        width="15"
        height="121"
        className={styles.bar}
      />
      <rect
        x="228.664"
        y="130.5"
        width="15"
        height="151"
        className={styles.bar}
      />
      <rect
        x="257.664"
        y="160.5"
        width="15"
        height="121"
        className={styles.bar}
      />
      <rect
        x="286.664"
        y="160.5"
        width="15"
        height="121"
        className={styles.bar}
      />
      <rect
        x="314.664"
        y="180.5"
        width="15"
        height="101"
        className={styles.bar}
      />
      <rect
        x="343.664"
        y="213.5"
        width="15"
        height="68"
        className={styles.bar}
      />
      <rect
        x="372.664"
        y="160.5"
        width="15"
        height="121"
        className={styles.bar}
      />
      <rect
        x="401.664"
        y="105.5"
        width="15"
        height="176"
        className={styles.bar}
      />
    </Explainer>
  );
};

export default Analytics;
