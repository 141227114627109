import gsap from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';

import saml1 from '@assets/images/explainers/saml1.png';
import saml2 from '@assets/images/explainers/saml2.png';

import Explainer from '../blocks';

gsap.registerPlugin(MotionPathPlugin);

const EXPLAINER_WIDTH = 411;
const EXPLAINER_HEIGHT = 280;

import styles from './SAML.module.css';
import { useEffect } from 'react';

const Analytics = () => {
  useEffect(() => {
    const circle = document.getElementsByClassName(styles.circle)[0];
    const paths = document.getElementsByClassName(styles.path);
    const tickBox = document.getElementsByClassName(styles.tickBox)[0];
    const tickText = document.getElementsByClassName(styles.tickText)[0];
    const tick = document.getElementsByClassName(styles.tick)[0];
    const error = document.getElementsByClassName(styles.error)[0];
    const errorBox = document.getElementsByClassName(styles.errorBox)[0];
    const errorText = document.getElementsByClassName(styles.errorText)[0];

    const timeline = gsap.timeline({ repeat: -1, repeatDelay: 1 });

    timeline
      .to(circle, {
        motionPath: {
          path: paths[0],
          autoRotate: false,
          alignOrigin: [0.5, 0.5],
        },
        duration: 2,
        ease: 'none',
      })
      .to(tick, { opacity: 1, duration: 0.3 }, '>-0.2')
      .to(tickText, { fill: '#57C7A4', duration: 0.3 }, '>-0.2')
      .to(tickBox, { fill: '#16464B', duration: 0.5 }, '>-0.3')
      .to(circle, {
        motionPath: {
          path: paths[1],
          autoRotate: false,
          alignOrigin: [0.5, 0.5],
        },
        duration: 2,
        ease: 'none',
      })
      .to(error, { opacity: 1, duration: 0.5 }, '>-0.2')
      .to(errorText, { fill: '#FF6183', duration: 0.3 }, '>-0.2')
      .to(errorBox, { fill: '#4B414E', duration: 0.5 }, '>-0.3');

    timeline.play();
  }, []);

  return (
    <Explainer
      width={EXPLAINER_WIDTH}
      height={EXPLAINER_HEIGHT}
      className={styles.root}
    >
      <g transform="translate(0 -30)">
        <path
          d="M80.4062 154H120.406C125.929 154 130.406 149.523 130.406 144V110C130.406 104.477 134.883 100 140.406 100H180.406"
          className={styles.path}
        />
        <path
          d="M80.4062 164H120.406C125.929 164 130.406 168.477 130.406 174V208C130.406 213.523 134.883 218 140.406 218H180.406"
          className={styles.path}
        />
        <circle
          cx="0"
          cy="0"
          r="7.5"
          fill="white"
          fillOpacity="0.75"
          className={styles.circle}
        />

        <rect
          x="170.262"
          y="71"
          width="204"
          height="60"
          rx="6"
          fill="#1C3945"
          className={styles.tickBox}
        />
        <rect x="180.262" y="83" width="36" height="36" rx="5" fill="#092F45" />
        <image
          xlinkHref={saml1.src}
          x="180.262"
          y="83"
          width="36"
          height="36"
        />

        <text
          x="226.692"
          y="97.64"
          fill="white"
          fontSize="14px"
          className={styles.tickText}
        >
          Jerôme Rodrigues
        </text>
        <text x="226.374" y="116.036" fill="#8497A2" fontSize="12px">
          @laffertyish
        </text>
        <rect
          x="170.094"
          y="189"
          width="204"
          height="60"
          rx="6"
          fill="#1C444C"
          className={styles.errorBox}
        />
        <image
          xlinkHref={saml2.src}
          x="180.094"
          y="201"
          width="36"
          height="36"
        />
        <text
          x="226.692"
          y="215.64"
          fill="white"
          fontSize="14px"
          className={styles.errorText}
        >
          Jack Grenouille
        </text>
        <text x="226.374" y="235.036" fill="#8497A2" fontSize="12px">
          @jgrenouille
        </text>
        <rect
          x="36.4062"
          y="131"
          width="54"
          height="54"
          rx="6"
          fill="#1C3E47"
        />
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M65.0915 157.533H75.2414L78.0397 160.38V163.392L73.8922 166.261L73.0215 165.003L76.5092 162.59V161.007L74.5996 159.063H65.7356L63.9717 160.867V162.424H62.4413V160.243L65.0915 157.533Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M70.2789 154.726C72.0157 154.726 73.4237 153.318 73.4237 151.582C73.4237 149.845 72.0157 148.437 70.2789 148.437C68.542 148.437 67.134 149.845 67.134 151.582C67.134 153.318 68.542 154.726 70.2789 154.726ZM70.2789 156.257C72.861 156.257 74.9542 154.164 74.9542 151.582C74.9542 148.999 72.861 146.906 70.2789 146.906C67.6968 146.906 65.6036 148.999 65.6036 151.582C65.6036 154.164 67.6968 156.257 70.2789 156.257Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.9244 162.234H60.1497V160.703H53.2826L50.4844 163.551V166.563L54.6318 169.432L55.5026 168.173L52.0148 165.76V164.177L53.9244 162.234Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M57.5196 157.178C59.2182 157.178 60.5952 155.801 60.5952 154.102C60.5952 152.403 59.2182 151.026 57.5196 151.026C55.8209 151.026 54.4439 152.403 54.4439 154.102C54.4439 155.801 55.8209 157.178 57.5196 157.178ZM57.5196 158.708C60.0634 158.708 62.1257 156.646 62.1257 154.102C62.1257 151.558 60.0634 149.496 57.5196 149.496C54.9757 149.496 52.9134 151.558 52.9134 154.102C52.9134 156.646 54.9757 158.708 57.5196 158.708Z"
            fill="white"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M356.202 105.036L362.049 97.5319L361.26 96.9173L355.714 104.036L355.03 104.036L351.631 100.196L350.883 100.859L354.579 105.036L356.202 105.036Z"
          fill="var(--color-success)"
          className={styles.tick}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M355.385 219L352.203 222.182L352.91 222.89L356.092 219.708L359.274 222.89L359.981 222.182L356.799 219L359.981 215.818L359.274 215.111L356.092 218.293L352.91 215.111L352.203 215.818L355.385 219Z"
          fill="var(--brand-color-red-5)"
          className={styles.error}
        />
      </g>
    </Explainer>
  );
};

export default Analytics;
