import Explainer from '../blocks';

const EXPLAINER_WIDTH = 411;
const EXPLAINER_HEIGHT = 270;

import styles from './DistributedTeams.module.css';

const DistributedTeams = () => {
  return (
    <Explainer
      width={EXPLAINER_WIDTH}
      height={EXPLAINER_HEIGHT}
      className={styles.root}
    >
      <g clipPath="url(#clip0_507_10332)" className={styles.ui}>
        <g filter="url(#filter0_d_507_10332)">
          <path
            d="M40 44C40 41.7909 41.7909 40 44 40H411V87H40V44Z"
            fill="#0A1B2A"
          />
          <path d="M257.667 87H410.667V270H257.667V87Z" fill="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.1902 58.209H66.4506L67.6404 59.3987V68.2013L66.4506 69.3911H63.2634V68.3911H66.0364L66.6404 67.7871V59.8129L66.0364 59.209L60.6044 59.209L60.0005 59.8129V63.2455H59.0005V59.3987L60.1902 58.209ZM57.7008 65.389H62.1888V64.489H57.7008V65.389ZM62.9601 67.389H57.7008V66.489H62.9601V67.389ZM57.7008 69.389H60.7319V68.489H57.7008V69.389Z"
            fill="white"
          />
          <text
            fill="white"
            fontFamily="var(--font-family-body)"
            fontSize="12.5"
            letterSpacing="0.03em"
          >
            <tspan x="79" y="67.625">
              Documentation
            </tspan>
          </text>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M196 58L203.5 58L205 59.5V60.5L203.5 62H200.5V63H204L204.5 63.5L205 64V66L204.5 66.5L204 67H200.5V70H199.5V67L196.5 67L195 65.5V64.5L196.5 63L199.5 63V62H196L195.5 61.5L195 61V59L195.5 58.5L196 58ZM195.979 59H197H198H201H203L204.021 60L203 61H195.979V60V59ZM204.021 66H203L201 66H198L197 66L195.979 65L197 64L204.021 64V66Z"
            fill="#5B798A"
          />
          <text
            fill="white"
            fontFamily="var(--font-family-body)"
            fontSize="12.5"
            letterSpacing="0.03em"
          >
            <tspan x="216" y="67.625">
              Guides
            </tspan>
          </text>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M290.735 58.5L281.265 58.5L280 59.9222V68.0778L281.265 69.5L290.735 69.5L292 68.0778V59.9222L290.735 58.5ZM281.671 59.6L280.979 60.3778V67.6222L281.671 68.4L290.329 68.4L291.021 67.6222V60.3778L290.329 59.6L287.15 59.6L283.85 59.6L281.671 59.6Z"
            fill="#5B798A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M283.276 63.0599L281.881 61.7742L282.532 61.0678L284.237 62.6389L284.237 63.5295L282.536 65.1357L281.877 64.4374L283.276 63.1155L283.276 63.0599Z"
            fill="#5B798A"
          />
          <text
            fill="white"
            fontFamily="var(--font-family-body)"
            fontSize="12.5"
            letterSpacing="0.03em"
          >
            <tspan x="300" y="67.625">
              API Reference
            </tspan>
          </text>
          <path d="M40 87H257V270H40V87Z" fill="white" />
          <rect
            width="185"
            height="16"
            transform="translate(55.668 223)"
            fill="white"
            fillOpacity="0.9"
          />
          <text
            fill="#0A2F45"
            fontFamily="var(--font-family-body)"
            fontSize="12.5"
            letterSpacing="0.03em"
          >
            <tspan x="55.668" y="234.625">
              Community
            </tspan>
          </text>
          <rect
            width="185"
            height="16"
            transform="translate(56 103)"
            fill="white"
            fillOpacity="0.9"
          />
          <text
            fill="#0A2F45"
            fontFamily="var(--font-family-body)"
            fontSize="12.5"
            letterSpacing="0.03em"
          >
            <tspan x="56" y="114.625">
              Getting started
            </tspan>
          </text>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M231.352 116.417L230.645 115.71L234.755 111.6V110.281L230.651 106.301L231.347 105.583L235.755 109.857V112.015L231.352 116.417Z"
            fill="#5B798A"
          />
          <rect
            width="185"
            height="16"
            transform="translate(56 133)"
            fill="white"
            fillOpacity="0.9"
          />
          <text
            fill="#0A2F45"
            fontFamily="var(--font-family-body)"
            fontSize="12.5"
            letterSpacing="0.03em"
          >
            <tspan x="56" y="144.625">
              Migration to Cloudsmith
            </tspan>
          </text>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M231.352 146.417L230.645 145.71L234.755 141.6V140.281L230.651 136.301L231.347 135.583L235.755 139.857V142.015L231.352 146.417Z"
            fill="#5B798A"
          />
          <rect
            width="185"
            height="16"
            transform="translate(56 163)"
            fill="white"
            fillOpacity="0.9"
          />
          <text
            fill="#0A2F45"
            fontFamily="var(--font-family-body)"
            fontSize="12.5"
            letterSpacing="0.03em"
          >
            <tspan x="56" y="174.625">
              Supported Formats
            </tspan>
          </text>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M231.352 176.417L230.645 175.71L234.755 171.6V170.281L230.651 166.301L231.347 165.583L235.755 169.857V172.015L231.352 176.417Z"
            fill="#5B798A"
          />
          <rect
            width="185"
            height="16"
            transform="translate(56 193)"
            fill="white"
            fillOpacity="0.9"
          />
          <text
            fill="#0A2F45"
            fontFamily="var(--font-family-body)"
            fontSize="12.5"
            letterSpacing="0.03em"
          >
            <tspan x="56" y="204.625">
              Integrations
            </tspan>
          </text>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M231.352 206.417L230.645 205.71L234.755 201.6V200.281L230.651 196.301L231.347 195.583L235.755 199.857V202.015L231.352 206.417Z"
            fill="#5B798A"
          />
          <text
            fill="#0A2F45"
            fontFamily="var(--font-family-body)"
            fontSize="12.5"
            letterSpacing="0.03em"
          >
            <tspan x="56" y="261.625">
              Troubleshooting
            </tspan>
          </text>
          <rect x="56" y="84" width="113" height="3" fill="#EBBB6A" />
        </g>
        <rect x="273" y="115" width="166" height="24" fill="#E1EAF3" />
        <rect x="273" y="143" width="166" height="12" fill="#E1EAF3" />
        <rect x="272.668" y="205" width="166" height="65" fill="#E1EAF3" />
        <rect x="273" y="103" width="58" height="8" fill="#E1EAF3" />
        <rect x="273" y="182" width="166" height="6" fill="#E1EAF3" />
        <rect x="273" y="193" width="166" height="6" fill="#E1EAF3" />
      </g>
      <defs>
        <filter
          id="filter0_d_507_10332"
          x="10"
          y="30"
          width="431"
          height="290"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="20" />
          <feGaussianBlur stdDeviation="15" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.741176 0 0 0 0 0.619608 0 0 0 0 1 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_507_10332"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_507_10332"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_507_10332">
          <rect width="411" height="270" fill="white" />
        </clipPath>
      </defs>
    </Explainer>
  );
};

export default DistributedTeams;
