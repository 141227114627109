import Explainer from '../blocks';

const EXPLAINER_WIDTH = 411;
const EXPLAINER_HEIGHT = 280;

import styles from './EntitlementTokens.module.css';

const EntitlementTokens = () => {
  return (
    <Explainer
      width={EXPLAINER_WIDTH}
      height={EXPLAINER_HEIGHT}
      className={styles.root}
    >
      <g className={styles.ui}>
        <g clipPath="url(#clip0_506_10261)">
          <g filter="url(#filter0_d_506_10261)">
            <g filter="url(#filter1_d_506_10261)">
              <g clipPath="url(#clip1_506_10261)">
                <rect
                  width="234"
                  height="56"
                  transform="translate(32.8333 33)"
                  fill="white"
                  fillOpacity="0.9"
                />
                <text className={styles.hash} x="52.8333" y="65.2">
                  a9f3d7e2b6c1g8h5i4j0k2m7n9p3q6{' '}
                </text>
                <rect
                  width="115"
                  height="56"
                  transform="translate(266.833 33)"
                  fill="white"
                  fillOpacity="0.9"
                />
                <rect
                  x="286.833"
                  y="49.5"
                  width="75"
                  height="23"
                  rx="4"
                  fill="#E2FCF0"
                />
                <text className={styles.label} x="295.267" y="64.5">
                  READ ONLY
                </text>
              </g>
            </g>
            <g filter="url(#filter2_d_506_10261)">
              <g clipPath="url(#clip2_506_10261)">
                <rect
                  width="234"
                  height="56"
                  transform="translate(32.8333 99)"
                  fill="white"
                  fillOpacity="0.9"
                />
                <text className={styles.hash} x="52.8333" y="131.2">
                  mN7pQ5rT3vW1xZ9bC4F6H8J0K2LXYM
                </text>
                <rect
                  width="115"
                  height="56"
                  transform="translate(266.833 99)"
                  fill="white"
                  fillOpacity="0.9"
                />
                <rect
                  x="286.833"
                  y="115.5"
                  width="75"
                  height="23"
                  rx="4"
                  fill="#E2FCF0"
                />
                <text className={styles.label} x="295.267" y="130.5">
                  READ ONLY
                </text>
              </g>
            </g>
            <g opacity="0.9" filter="url(#filter3_d_506_10261)">
              <g clipPath="url(#clip3_506_10261)">
                <rect
                  width="234"
                  height="56"
                  transform="translate(32.8333 165)"
                  fill="white"
                  fillOpacity="0.9"
                />
                <text className={styles.hash}>
                  <tspan x="52.8333" y="197.2">
                    eyJhbGciOiJIUzI1NiIsInR5cCI6Ik
                  </tspan>
                </text>
                <rect
                  width="115"
                  height="56"
                  transform="translate(266.833 165)"
                  fill="white"
                  fillOpacity="0.9"
                />
                <rect
                  x="286.833"
                  y="181.5"
                  width="75"
                  height="23"
                  rx="4"
                  fill="#E2FCF0"
                />
                <text className={styles.label}>
                  <tspan x="295.267" y="196.5">
                    READ ONLY
                  </tspan>
                </text>
              </g>
            </g>
            <g opacity="0.8" filter="url(#filter4_d_506_10261)">
              <g clipPath="url(#clip4_506_10261)">
                <rect
                  width="234"
                  height="56"
                  transform="translate(32.8333 231)"
                  fill="white"
                  fillOpacity="0.9"
                />
                <text className={styles.hash}>
                  <tspan x="52.8333" y="263.2">
                    SflKxwRJSMeKKF2QT4fwpMeJf36POk6
                  </tspan>
                </text>
                <rect
                  width="115"
                  height="56"
                  transform="translate(266.833 231)"
                  fill="white"
                  fillOpacity="0.9"
                />
                <rect
                  x="286.833"
                  y="247.5"
                  width="75"
                  height="23"
                  rx="4"
                  fill="#E2FCF0"
                />
                <text className={styles.label}>
                  <tspan x="295.267" y="262.5">
                    READ ONLY
                  </tspan>
                </text>
              </g>
            </g>
            <g opacity="0.7">
              <g clipPath="url(#clip5_506_10261)">
                <g clipPath="url(#clip6_506_10261)">
                  <rect
                    width="234"
                    height="56"
                    transform="translate(32.8333 297)"
                    fill="white"
                  />
                </g>
                <rect
                  width="115"
                  height="56"
                  transform="translate(266.833 297)"
                  fill="white"
                />
                <rect
                  x="286.833"
                  y="313.5"
                  width="75"
                  height="23"
                  rx="4"
                  fill="#E1FBEF"
                />
              </g>
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_506_10261"
            x="2.83331"
            y="23"
            width="409"
            height="380"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="20" />
            <feGaussianBlur stdDeviation="15" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.768627 0 0 0 0 0.956863 0 0 0 0 0.878431 0 0 0 0.15 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_506_10261"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_506_10261"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_506_10261"
            x="22.8333"
            y="31"
            width="369"
            height="76"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="8" />
            <feGaussianBlur stdDeviation="5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0.627451 0 0 0 0 0.517647 0 0 0 0.15 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_506_10261"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_506_10261"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_506_10261"
            x="22.8333"
            y="97"
            width="369"
            height="76"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="8" />
            <feGaussianBlur stdDeviation="5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0.627451 0 0 0 0 0.517647 0 0 0 0.15 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_506_10261"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_506_10261"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_506_10261"
            x="22.8333"
            y="163"
            width="369"
            height="76"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="8" />
            <feGaussianBlur stdDeviation="5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0.627451 0 0 0 0 0.517647 0 0 0 0.15 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_506_10261"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_506_10261"
              result="shape"
            />
          </filter>
          <filter
            id="filter4_d_506_10261"
            x="22.8333"
            y="229"
            width="369"
            height="76"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="8" />
            <feGaussianBlur stdDeviation="5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0.627451 0 0 0 0 0.517647 0 0 0 0.15 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_506_10261"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_506_10261"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_506_10261">
            <rect width="411" height="320" fill="white" />
          </clipPath>
          <clipPath id="clip1_506_10261">
            <path
              d="M32.8333 39C32.8333 35.6863 35.5196 33 38.8333 33H375.833C379.147 33 381.833 35.6863 381.833 39V83C381.833 86.3137 379.147 89 375.833 89H38.8333C35.5196 89 32.8333 86.3137 32.8333 83V39Z"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip2_506_10261">
            <path
              d="M32.8333 105C32.8333 101.686 35.5196 99 38.8333 99H375.833C379.147 99 381.833 101.686 381.833 105V149C381.833 152.314 379.147 155 375.833 155H38.8333C35.5196 155 32.8333 152.314 32.8333 149V105Z"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip3_506_10261">
            <path
              d="M32.8333 171C32.8333 167.686 35.5196 165 38.8333 165H375.833C379.147 165 381.833 167.686 381.833 171V215C381.833 218.314 379.147 221 375.833 221H38.8333C35.5196 221 32.8333 218.314 32.8333 215V171Z"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip4_506_10261">
            <path
              d="M32.8333 237C32.8333 233.686 35.5196 231 38.8333 231H375.833C379.147 231 381.833 233.686 381.833 237V281C381.833 284.314 379.147 287 375.833 287H38.8333C35.5196 287 32.8333 284.314 32.8333 281V237Z"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip5_506_10261">
            <path
              d="M32.8333 303C32.8333 299.686 35.5196 297 38.8333 297H375.833C379.147 297 381.833 299.686 381.833 303V347C381.833 350.314 379.147 353 375.833 353H38.8333C35.5196 353 32.8333 350.314 32.8333 347V303Z"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip6_506_10261">
            <rect
              width="234"
              height="56"
              fill="white"
              transform="translate(32.8333 297)"
            />
          </clipPath>
        </defs>
      </g>
    </Explainer>
  );
};

export default EntitlementTokens;
