import React from 'react';
import Explainer from '../blocks';

import * as styles from './Logging.module.css';

const EXPLAINER_WIDTH = 411;
const EXPLAINER_HEIGHT = 280;

const Logging = () => {
  return (
    <Explainer
      width={EXPLAINER_WIDTH}
      height={EXPLAINER_HEIGHT}
      className={styles.root}
    >
      <g className={styles.ui}>
        <g className={styles.scroller}>
          <path
            d="M40.332 65H529.332V120H40.332V65Z"
            fill="white"
            fillOpacity="0.1"
          />
          <line
            x1="529.332"
            y1="119"
            x2="40.332"
            y2="119"
            className={styles.line}
          />

          <path
            opacity="0.5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.6288 86.9785H68.0356L69.3285 88.2714V92.3389L68.0356 93.6318L60.6288 93.6318L59.3359 92.3389V88.2714L60.6288 86.9785ZM61.043 87.9785L60.3359 88.6856V91.9247L61.043 92.6318L67.6214 92.6318L68.3285 91.9247V88.6856L67.6214 87.9785L61.043 87.9785ZM59.9583 98.155L62.042 95.8112H62.5834H65.956H66.4974L68.5811 98.155L69.3285 97.4906L66.9464 94.8112H65.956H62.5834H61.593L59.2109 97.4906L59.9583 98.155ZM62.7088 91.7148L62.7088 88.8955L61.7088 88.8955L61.7088 91.7148H62.7088ZM64.8322 88.8955V91.7148H63.8322V88.8955L64.8322 88.8955ZM66.9556 91.7148V88.8955L65.9556 88.8955V91.7148H66.9556Z"
            fill="white"
          />

          <text className={styles.user} x="84.332" y="96.56">
            pipeline.service
          </text>
          <rect
            x="220.332"
            y="81"
            width="36"
            height="23"
            rx="4"
            fill="white"
            fillOpacity="0.15"
          />
          <text className={styles.label} x="228.777" y="96">
            GET
          </text>

          <path
            d="M40.332 120H529.332V175H40.332V120Z"
            fill="white"
            fillOpacity="0.1"
          />
          <line
            x1="529.332"
            y1="174"
            x2="40.332"
            y2="174"
            className={styles.line}
          />
          <text
            opacity="0.5"
            fill="white"
            fontFamily="var(--font-family-mono)"
            fontSize="11"
            letterSpacing="0.0075em"
            x="56.332"
            y="151.35"
          >
            SYSTEM
          </text>
          <rect
            x="220.332"
            y="136"
            width="166"
            height="23"
            rx="4"
            fill="white"
            fillOpacity="0.15"
          />
          <text className={styles.label} x="228.742" y="151">
            CLEANUP_UPSTREAM_CACHES
          </text>

          <path
            d="M40.332 175H529.332V230H40.332V175Z"
            fill="white"
            fillOpacity="0.1"
          />
          <line
            x1="529.332"
            y1="229"
            x2="40.332"
            y2="229"
            className={styles.line}
          />

          <path
            opacity="0.5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M61.6773 200.178C61.6773 198.711 62.8665 197.521 64.3335 197.521C65.8005 197.521 66.9897 198.711 66.9897 200.178C66.9897 201.645 65.8005 202.834 64.3335 202.834C62.8665 202.834 61.6773 201.645 61.6773 200.178ZM60.6773 200.178C60.6773 198.158 62.3143 196.521 64.3335 196.521C66.3528 196.521 67.9897 198.158 67.9897 200.178C67.9897 202.197 66.3528 203.834 64.3335 203.834C62.3143 203.834 60.6773 202.197 60.6773 200.178ZM61.2764 205.968L59.1927 208.311L58.4453 207.647L60.8274 204.968H63.4767H65.1904H67.8397L70.2217 207.647L69.4744 208.311L67.3906 205.968H65.1904H63.4767H61.2764Z"
            fill="white"
          />

          <text className={styles.user} x="84.332" y="206.56">
            admin.user
          </text>
          <rect
            x="220.332"
            y="191"
            width="57"
            height="23"
            rx="4"
            fill="white"
            fillOpacity="0.15"
          />
          <text className={styles.label} x="228.521" y="206">
            DELETE
          </text>

          <path
            d="M40.332 230H529.332V285H40.332V230Z"
            fill="white"
            fillOpacity="0.1"
          />
          <line
            x1="529.332"
            y1="284"
            x2="40.332"
            y2="284"
            className={styles.line}
          />

          <path
            opacity="0.5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.6288 251.979H68.0356L69.3285 253.271V257.339L68.0356 258.632L60.6288 258.632L59.3359 257.339V253.271L60.6288 251.979ZM61.043 252.979L60.3359 253.686V256.925L61.043 257.632L67.6214 257.632L68.3285 256.925V253.686L67.6214 252.979L61.043 252.979ZM59.9583 263.155L62.042 260.811H62.5834H65.956H66.4974L68.5811 263.155L69.3285 262.491L66.9464 259.811H65.956H62.5834H61.593L59.2109 262.491L59.9583 263.155ZM62.7088 256.715L62.7088 253.895L61.7088 253.895L61.7088 256.715H62.7088ZM64.8322 253.895V256.715H63.8322V253.895L64.8322 253.895ZM66.9556 256.715V253.895L65.9556 253.895V256.715H66.9556Z"
            fill="white"
          />

          <text className={styles.user} x="84.332" y="261.56">
            pipeline.service
          </text>
          <rect
            x="220.332"
            y="246"
            width="49"
            height="23"
            rx="4"
            fill="white"
            fillOpacity="0.15"
          />
          <text className={styles.label} x="228.773" y="261">
            PATCH
          </text>

          <path
            d="M40.332 285H529.332V340H40.332V285Z"
            fill="white"
            fillOpacity="0.1"
          />
          <line
            x1="529.332"
            y1="339"
            x2="40.332"
            y2="339"
            className={styles.line}
          />

          <path
            opacity="0.5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.6288 306.979H68.0356L69.3285 308.271V312.339L68.0356 313.632L60.6288 313.632L59.3359 312.339V308.271L60.6288 306.979ZM61.043 307.979L60.3359 308.686V311.925L61.043 312.632L67.6214 312.632L68.3285 311.925V308.686L67.6214 307.979L61.043 307.979ZM59.9583 318.155L62.042 315.811H62.5834H65.956H66.4974L68.5811 318.155L69.3285 317.491L66.9464 314.811H65.956H62.5834H61.593L59.2109 317.491L59.9583 318.155ZM62.7088 311.715L62.7088 308.895L61.7088 308.895L61.7088 311.715H62.7088ZM64.8322 308.895V311.715H63.8322V308.895L64.8322 308.895ZM66.9556 311.715V308.895L65.9556 308.895V311.715H66.9556Z"
            fill="white"
          />

          <text className={styles.user} x="84.332" y="316.56">
            pipeline.service
          </text>
          <rect
            x="220.332"
            y="301"
            width="36"
            height="23"
            rx="4"
            fill="white"
            fillOpacity="0.15"
          />
          <text className={styles.label} x="228.777" y="316">
            GET
          </text>

          <g transform="translate(0, 55)">
            <path
              d="M40.332 285H529.332V340H40.332V285Z"
              fill="white"
              fillOpacity="0.1"
            />
            <line
              x1="529.332"
              y1="339"
              x2="40.332"
              y2="339"
              className={styles.line}
            />
            <path
              opacity="0.5"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M60.6288 306.979H68.0356L69.3285 308.271V312.339L68.0356 313.632L60.6288 313.632L59.3359 312.339V308.271L60.6288 306.979ZM61.043 307.979L60.3359 308.686V311.925L61.043 312.632L67.6214 312.632L68.3285 311.925V308.686L67.6214 307.979L61.043 307.979ZM59.9583 318.155L62.042 315.811H62.5834H65.956H66.4974L68.5811 318.155L69.3285 317.491L66.9464 314.811H65.956H62.5834H61.593L59.2109 317.491L59.9583 318.155ZM62.7088 311.715L62.7088 308.895L61.7088 308.895L61.7088 311.715H62.7088ZM64.8322 308.895V311.715H63.8322V308.895L64.8322 308.895ZM66.9556 311.715V308.895L65.9556 308.895V311.715H66.9556Z"
              fill="white"
            />

            <text className={styles.user} x="84.332" y="316.56">
              pipeline.service
            </text>
            <rect
              x="220.332"
              y="301"
              width="36"
              height="23"
              rx="4"
              fill="white"
              fillOpacity="0.15"
            />
            <text className={styles.label} x="228.777" y="316">
              GET
            </text>
          </g>
        </g>
        <path
          d="M40.332 30C40.332 26.6863 43.0183 24 46.332 24H523.332C526.646 24 529.332 26.6863 529.332 30V65H40.332V30Z"
          fill="#21313f"
        />
        <line
          x1="529.332"
          y1="64"
          x2="40.332"
          y2="64"
          className={styles.line}
        />
        <text className={styles.header} x="59.332" y="48.85">
          USER
        </text>
        <text className={styles.header} x="220.332" y="48.85">
          EVENT
        </text>
      </g>
      <rect
        x="0"
        y="0"
        width={EXPLAINER_WIDTH}
        height={EXPLAINER_HEIGHT}
        fill="url(#gradient-cover)"
      />
      <defs>
        <radialGradient id="gradient-cover" cy="200%" r="200%">
          <stop
            offset="0%"
            stopColor="var(--brand-color-red-6)"
            stopOpacity="0.35"
          />
          <stop
            offset="100%"
            stopColor="var(--brand-color-red-6)"
            stopOpacity="0"
          />
        </radialGradient>
      </defs>
    </Explainer>
  );
};

export default Logging;
