import gsap from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';

import Explainer from '../blocks';

gsap.registerPlugin(MotionPathPlugin);

const EXPLAINER_WIDTH = 411;
const EXPLAINER_HEIGHT = 280;

import styles from './Quarantine.module.css';
import { useEffect } from 'react';

const Quarantine = () => {
  useEffect(() => {
    const circle = document.getElementsByClassName(styles.circle)[0];
    const path = document.getElementsByClassName(styles.path)[0];

    const timeline = gsap.timeline({ repeat: -1, repeatDelay: 1 });

    timeline
      .to(circle, {
        motionPath: {
          path: path,
          autoRotate: false,
          alignOrigin: [0.5, 0.5],
        },
        duration: 3,
        ease: 'none',
      })
      .to(
        `.${styles.logo}`,
        {
          rotation: `+=360`,
          transformOrigin: '50% 50%',
          duration: 1,
          ease: 'power1.inOut',
        },
        1.2,
      )
      .to(
        `.${styles.pending}`,
        {
          y: `+=10`,
          opacity: 0,
          duration: 0.5,
          ease: 'power1.Out',
        },
        '<',
      )
      .to(
        `.${styles.check}`,
        {
          opacity: 1,
          duration: 0.5,
          ease: 'power1.Out',
        },
        '<',
      );

    timeline.play();
  }, []);

  return (
    <Explainer
      width={EXPLAINER_WIDTH}
      height={EXPLAINER_HEIGHT}
      className={styles.root}
    >
      <g transform="translate(0 -20)">
        <path
          d="M 150.5 70 L 150.5 148 C150.5 153.523 154.977 158 160.5 158L163.5 158L250 157.5C255.523 157.5 260 161.977 260 167.5L260 240.5"
          fill="none"
          stroke="var(--brand-color-purple-5)"
          strokeDasharray="2 2"
          className={styles.path}
        />

        <circle
          cx="0"
          cy="0"
          r="7.5"
          fill="white"
          fillOpacity="0.75"
          className={styles.circle}
        />
        <rect x="178" y="132" width="54" height="54" rx="6" fill="#2B3956" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M218 157.5V160.5L206.444 172.5H203.556L192 160.5V157.5L203.556 145.5H206.444L218 157.5ZM204.999 165.227C208.31 165.227 210.995 162.439 210.995 159C210.995 155.561 208.31 152.773 204.999 152.773C201.687 152.773 199.002 155.561 199.002 159C199.002 162.439 201.687 165.227 204.999 165.227Z"
          fill="white"
          className={styles.logo}
        />
        <rect x="158" y="216" width="204" height="43" rx="6" fill="#333D62" />
        <text x="168.176" y="242" fill="white" fontSize="14">
          Production
        </text>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M343.95 242.544C346.735 242.544 348.993 240.286 348.993 237.501C348.993 234.715 346.735 232.457 343.95 232.457C341.164 232.457 338.906 234.715 338.906 237.501C338.906 240.286 341.164 242.544 343.95 242.544ZM343.95 243.544C347.288 243.544 349.993 240.838 349.993 237.501C349.993 234.163 347.288 231.457 343.95 231.457C340.612 231.457 337.906 234.163 337.906 237.501C337.906 240.838 340.612 243.544 343.95 243.544ZM342.193 237.501C342.193 238.012 341.779 238.426 341.268 238.426C340.757 238.426 340.343 238.012 340.343 237.501C340.343 236.99 340.757 236.576 341.268 236.576C341.779 236.576 342.193 236.99 342.193 237.501ZM344 238.426C344.51 238.426 344.925 238.012 344.925 237.501C344.925 236.99 344.51 236.576 344 236.576C343.489 236.576 343.075 236.99 343.075 237.501C343.075 238.012 343.489 238.426 344 238.426ZM346.731 238.426C347.242 238.426 347.656 238.012 347.656 237.501C347.656 236.99 347.242 236.576 346.731 236.576C346.22 236.576 345.806 236.99 345.806 237.501C345.806 238.012 346.22 238.426 346.731 238.426Z"
          fill="var(--base-color-grey-600)"
          className={styles.pending}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M343.94 241.536L349.787 234.032L348.998 233.417L343.452 240.536L342.768 240.536L339.37 236.696L338.621 237.359L342.317 241.536L343.94 241.536Z"
          fill="var(--color-success)"
          className={styles.check}
        />
        <rect x="48" y="59" width="204" height="43" rx="6" fill="#23354A" />
        <text x="58.228" y="85.14" fill="white" fontSize="14">
          Staging
        </text>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.94 84.5361L239.787 77.0319L238.998 76.4173L233.452 83.5361L232.768 83.5361L229.37 79.6961L228.621 80.3588L232.317 84.5361L233.94 84.5361Z"
          fill="var(--color-success)"
        />
      </g>
    </Explainer>
  );
};

export default Quarantine;
