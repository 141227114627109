import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import explainerMap from './map';

export default function Explainer({ explainer }) {
  const ExplainerComponent = explainerMap[explainer];

  if (!ExplainerComponent) {
    console.error(`Explainer ${explainer} not found`);
    return null;
  }

  return (
    <Suspense fallback={<svg />}>
      <ExplainerComponent />
    </Suspense>
  );
}

Explainer.propTypes = {
  explainer: PropTypes.oneOf(Object.keys(explainerMap)).isRequired,
};
