import Explainer from '../blocks';
import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import * as styles from './ContainerRegistry.module.css';

const CARD_HEIGHT = 56;
const CARD_LEFT_OFFSET = 20;
const CARD_TOP_OFFSET = 0;
const CARD_GAP = 10;
const CARD_TIME = 2000;

const Card = ({ children, index, cardState }) => {
  return (
    <g
      className={cn(styles.card, {
        [styles.activeInProgress]:
          cardState.active && cardState.phase !== undefined,
        [styles.activePhaseOne]: cardState.active && cardState.phase === 0,
        [styles.activePhaseTwo]: cardState.active && cardState.phase === 1,
        [styles.activePhaseThree]: cardState.phase === 2,
      })}
      style={{
        transform: `translate(${CARD_LEFT_OFFSET}px, ${
          CARD_TOP_OFFSET + (CARD_HEIGHT * index + CARD_GAP * index)
        }px)`,
      }}
    >
      {children}
    </g>
  );
};

const ContainerRegistry = () => {
  const icons = (
    <g className={cn(styles.icons)}>
      <g className={cn(styles.iconDefault, styles.icon)}>
        <rect width="16" height="16" fill="transparent" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.94986 13.0443C10.7354 13.0443 12.9935 10.7861 12.9935 8.00064C12.9935 5.21513 10.7354 2.95703 7.94986 2.95703C5.16435 2.95703 2.90625 5.21513 2.90625 8.00064C2.90625 10.7861 5.16435 13.0443 7.94986 13.0443ZM7.94986 14.0443C11.2877 14.0443 13.9935 11.3384 13.9935 8.00064C13.9935 4.66285 11.2877 1.95703 7.94986 1.95703C4.61207 1.95703 1.90625 4.66285 1.90625 8.00064C1.90625 11.3384 4.61207 14.0443 7.94986 14.0443ZM6.19326 8.00067C6.19326 8.51153 5.77913 8.92567 5.26826 8.92567C4.7574 8.92567 4.34326 8.51153 4.34326 8.00067C4.34326 7.4898 4.7574 7.07567 5.26826 7.07567C5.77913 7.07567 6.19326 7.4898 6.19326 8.00067ZM7.99959 8.92567C8.51045 8.92567 8.92458 8.51153 8.92458 8.00067C8.92458 7.4898 8.51045 7.07567 7.99959 7.07567C7.48872 7.07567 7.07458 7.4898 7.07458 8.00067C7.07458 8.51153 7.48872 8.92567 7.99959 8.92567ZM10.731 8.92567C11.2418 8.92567 11.656 8.51153 11.656 8.00067C11.656 7.4898 11.2418 7.07567 10.731 7.07567C10.2201 7.07567 9.80597 7.4898 9.80597 8.00067C9.80597 8.51153 10.2201 8.92567 10.731 8.92567Z"
          fill="var(--base-color-grey-600)"
        />
      </g>
      <g className={cn(styles.iconSpinning, styles.icon)}>
        <rect width="16" height="16" fill="transparent" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 7.22222L1 8.77778L7.22222 15L8.77778 15L15 8.77778L15 7.22222L8.77778 1L7.22222 1L1 7.22222ZM7.99743 11.2279C6.21423 11.2279 4.76865 9.7823 4.76865 7.99909C4.76865 6.21589 6.21423 4.77031 7.99743 4.77031C9.78064 4.77031 11.2262 6.21589 11.2262 7.99909C11.2262 9.7823 9.78064 11.2279 7.99743 11.2279Z"
          fill="var(--base-color-blue-500)"
        />
      </g>
      <g className={cn(styles.iconChecked, styles.icon)}>
        <rect width="16" height="16" fill="transparent" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.94044 12.0361L13.7873 4.53186L12.9984 3.91725L7.45187 11.0361L6.76779 11.0361L3.36968 7.19612L2.6208 7.85882L6.31739 12.0361L7.94044 12.0361Z"
          fill="var(--brand-color-green-6)"
        />
      </g>
    </g>
  );

  const cards = [
    <g className={styles.cardInner} key="card1">
      <g className={styles.background} filter="url(#filter0_d_2525_4703)">
        <path d="M10 8C10 4.68629 12.6863 2 16 2H531C534.314 2 537 4.68629 537 8V52C537 55.3137 534.314 58 531 58H16C12.6863 58 10 55.3137 10 52V8Z" />
      </g>
      {icons}
      <path
        d="M30 24.5C30 22.2909 31.7909 20.5 34 20.5H60C62.2091 20.5 64 22.2909 64 24.5V35.5C64 37.7091 62.2091 39.5 60 39.5H34C31.7909 39.5 30 37.7091 30 35.5V24.5Z"
        fill="var(--brand-color-blue-1)"
      />
      <path
        d="M40.8 33.6004C39 33.6004 38 32.4004 38 30.0004C38 27.6004 39 26.4004 40.8 26.4004C42.3 26.4004 43.2 27.2004 43.3 28.5004L43.2 28.6004H42.4L42.3 28.5004C42.2 27.8004 41.8 27.3004 40.8 27.3004C39.6 27.3004 39 28.2004 39 30.0004C39 31.8004 39.6 32.7004 40.8 32.7004C41.9 32.7004 42.2 32.1004 42.3 31.4004L42.4 31.3004H43.2L43.3 31.4004C43.2 32.7004 42.4 33.6004 40.8 33.6004Z"
        fill="var(--brand-color-blue-7)"
      />
      <path
        d="M49.6035 33.4004L49.5035 33.5004H48.7035L48.6035 33.4004V29.1004H48.5035L47.6035 33.4004L47.5035 33.5004H46.8035L46.7035 33.4004L45.8035 29.1004H45.7035V33.4004L45.6035 33.5004H44.8035L44.7035 33.4004V26.6004L44.8035 26.5004H45.9035L46.0035 26.6004L47.1035 31.8004H47.2035L48.3035 26.6004L48.4035 26.5004H49.5035L49.6035 26.6004V33.4004Z"
        fill="var(--brand-color-blue-7)"
      />
      <path
        d="M56.207 30.0004C56.207 32.1004 55.607 33.5004 53.107 33.5004H51.407L51.307 33.4004V26.6004L51.407 26.5004H53.107C55.607 26.5004 56.207 27.9004 56.207 30.0004ZM55.207 30.0004C55.207 28.3004 54.907 27.4004 53.007 27.4004H52.407L52.307 27.5004V32.5004L52.407 32.6004H53.007C54.907 32.6004 55.207 31.7004 55.207 30.0004Z"
        fill="var(--brand-color-blue-7)"
      />
      <text
        x="84"
        y="34"
        fill="var(--brand-color-grey-8)"
        fontFamily="var(--font-family-mono)"
        fontSize="12"
      >
        file:3d9897cfe027ecc7cbdb16e74a676ed143725ea2d08dbb0dde…
      </text>
      <defs>
        <filter
          id="filter0_d_2525_4703"
          x="0"
          y="0"
          width="547"
          height="76"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.164706 0 0 0 0 0.435294 0 0 0 0 0.882353 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2525_4703"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2525_4703"
            result="shape"
          />
        </filter>
      </defs>
    </g>,
    <g className={styles.cardInner} key="card2">
      <g className={styles.background} filter="url(#filter0_d_2525_4702)">
        <path d="M10 8C10 4.68629 12.6863 2 16 2H531C534.314 2 537 4.68629 537 8V52C537 55.3137 534.314 58 531 58H16C12.6863 58 10 55.3137 10 52V8Z" />
      </g>
      {icons}
      <path
        d="M30 24.5C30 22.2909 31.7909 20.5 34 20.5H60C62.2091 20.5 64 22.2909 64 24.5V35.5C64 37.7091 62.2091 39.5 60 39.5H34C31.7909 39.5 30 37.7091 30 35.5V24.5Z"
        fill="var(--brand-color-blue-1)"
      />
      <path
        d="M42 28.4492C42 27.6492 41.6 27.3492 40.7 27.3492H39.1L39 27.4492V29.4492L39.1 29.5492H40.7C41.6 29.5492 42 29.2492 42 28.4492ZM43 28.4492C43 29.5492 42.4 30.1492 41.5 30.3492V30.4492L42.9 32.6492V33.3492L42.8 33.4492H42.4L42.2 33.3492L40.4 30.5492L40.2 30.4492H39.1L39 30.5492V33.3492L38.9 33.4492H38.1L38 33.3492V26.5492L38.1 26.4492H40.7C42.1 26.4492 43 27.0492 43 28.4492Z"
        fill="var(--brand-color-blue-7)"
      />
      <path
        d="M46.9035 33.5492H46.8035C45.1035 33.5492 44.4035 32.5492 44.4035 30.9492V26.5492L44.5035 26.4492H45.3035L45.4035 26.5492V30.9492C45.4035 31.9492 45.7035 32.6492 46.8035 32.6492H46.9035C48.0035 32.6492 48.3035 31.9492 48.3035 30.9492V26.5492L48.4035 26.4492H49.2035L49.3035 26.5492V30.9492C49.3035 32.5492 48.6035 33.5492 46.9035 33.5492Z"
        fill="var(--brand-color-blue-7)"
      />
      <path
        d="M55.707 33.3492L55.607 33.4492H54.707L54.507 33.3492L52.107 28.4492H52.007V33.3492L51.907 33.4492H51.107L51.007 33.3492V26.5492L51.107 26.4492H52.007L52.207 26.5492L54.607 31.4492H54.707V26.5492L54.807 26.4492H55.607L55.707 26.5492V33.3492Z"
        fill="var(--brand-color-blue-7)"
      />
      <text
        x="84"
        y="34"
        fill="var(--brand-color-grey-8)"
        fontFamily="var(--font-family-mono)"
        fontSize="12"
      >
        /bin/sh -c set -eux; groupadd -r -g 999 redis; useradd…
      </text>
      <defs>
        <filter
          id="filter0_d_2525_4702"
          x="0"
          y="0"
          width="547"
          height="76"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.164706 0 0 0 0 0.435294 0 0 0 0 0.882353 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2525_4702"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2525_4702"
            result="shape"
          />
        </filter>
      </defs>
    </g>,
    <g className={styles.cardInner} key="card3">
      <g className={styles.background} filter="url(#filter0_d_2525_4701)">
        <path d="M10 8C10 4.68629 12.6863 2 16 2H531C534.314 2 537 4.68629 537 8V52C537 55.3137 534.314 58 531 58H16C12.6863 58 10 55.3137 10 52V8Z" />
      </g>
      {icons}
      <path
        d="M30 24.5C30 22.2909 31.7909 20.5 34 20.5H60C62.2091 20.5 64 22.2909 64 24.5V35.5C64 37.7091 62.2091 39.5 60 39.5H34C31.7909 39.5 30 37.7091 30 35.5V24.5Z"
        fill="var(--brand-color-blue-1)"
      />
      <path
        d="M42.7 33.4L42.3 32.2L42.1 32.1H39.4L39.2 32.2L38.8 33.4L38.7 33.5H38.1L38 33.4V32.7L40.2 26.6L40.3 26.5H41.2L41.3 26.6L43.5 32.7V33.4L43.4 33.5H42.8L42.7 33.4ZM40.7 28L39.6 31.1L39.7 31.2H41.8L41.9 31.1L40.8 28H40.7Z"
        fill="var(--brand-color-blue-7)"
      />
      <path
        d="M49.8035 30C49.8035 32.1 49.2035 33.5 46.7035 33.5H45.0035L44.9035 33.4V26.6L45.0035 26.5H46.7035C49.2035 26.5 49.8035 27.9 49.8035 30ZM48.8035 30C48.8035 28.3 48.5035 27.4 46.6035 27.4H46.0035L45.9035 27.5V32.5L46.0035 32.6H46.6035C48.5035 32.6 48.8035 31.7 48.8035 30Z"
        fill="var(--brand-color-blue-7)"
      />
      <path
        d="M56.307 30C56.307 32.1 55.707 33.5 53.207 33.5H51.507L51.407 33.4V26.6L51.507 26.5H53.207C55.707 26.5 56.307 27.9 56.307 30ZM55.307 30C55.307 28.3 55.007 27.4 53.107 27.4H52.507L52.407 27.5V32.5L52.507 32.6H53.107C55.007 32.6 55.307 31.7 55.307 30Z"
        fill="var(--brand-color-blue-7)"
      />
      <text
        x="84"
        y="34"
        fill="var(--brand-color-grey-8)"
        fontFamily="var(--font-family-mono)"
        fontSize="12"
      >
        /bin/sh -c set -eux; apt-get update; apt-get install -…
      </text>
      <defs>
        <filter
          id="filter0_d_2525_4701"
          x="0"
          y="0"
          width="547"
          height="76"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.164706 0 0 0 0 0.435294 0 0 0 0 0.882353 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2525_4701"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2525_4701"
            result="shape"
          />
        </filter>
      </defs>
    </g>,
    <g className={styles.cardInner} key="card4">
      <g className={styles.background} filter="url(#filter0_d_2525_4700)">
        <path d="M10 8C10 4.68629 12.6863 2 16 2H531C534.314 2 537 4.68629 537 8V52C537 55.3137 534.314 58 531 58H16C12.6863 58 10 55.3137 10 52V8Z" />
      </g>
      {icons}
      <path
        d="M30 24.5C30 22.2909 31.7909 20.5 34 20.5H60C62.2091 20.5 64 22.2909 64 24.5V35.5C64 37.7091 62.2091 39.5 60 39.5H34C31.7909 39.5 30 37.7091 30 35.5V24.5Z"
        fill="var(--brand-color-blue-1)"
      />
      <path
        d="M42 28.4492C42 27.6492 41.6 27.3492 40.7 27.3492H39.1L39 27.4492V29.4492L39.1 29.5492H40.7C41.6 29.5492 42 29.2492 42 28.4492ZM43 28.4492C43 29.5492 42.4 30.1492 41.5 30.3492V30.4492L42.9 32.6492V33.3492L42.8 33.4492H42.4L42.2 33.3492L40.4 30.5492L40.2 30.4492H39.1L39 30.5492V33.3492L38.9 33.4492H38.1L38 33.3492V26.5492L38.1 26.4492H40.7C42.1 26.4492 43 27.0492 43 28.4492Z"
        fill="var(--brand-color-blue-7)"
      />
      <path
        d="M46.9035 33.5492H46.8035C45.1035 33.5492 44.4035 32.5492 44.4035 30.9492V26.5492L44.5035 26.4492H45.3035L45.4035 26.5492V30.9492C45.4035 31.9492 45.7035 32.6492 46.8035 32.6492H46.9035C48.0035 32.6492 48.3035 31.9492 48.3035 30.9492V26.5492L48.4035 26.4492H49.2035L49.3035 26.5492V30.9492C49.3035 32.5492 48.6035 33.5492 46.9035 33.5492Z"
        fill="var(--brand-color-blue-7)"
      />
      <path
        d="M55.707 33.3492L55.607 33.4492H54.707L54.507 33.3492L52.107 28.4492H52.007V33.3492L51.907 33.4492H51.107L51.007 33.3492V26.5492L51.107 26.4492H52.007L52.207 26.5492L54.607 31.4492H54.707V26.5492L54.807 26.4492H55.607L55.707 26.5492V33.3492Z"
        fill="var(--brand-color-blue-7)"
      />
      <text
        x="84"
        y="34"
        fill="var(--brand-color-grey-8)"
        fontFamily="var(--font-family-mono)"
        fontSize="12"
      >
        file:3d9897cfe027ecc7cbdb16e74a676ed143725ea2d08dbb0dde…
      </text>
      <defs>
        <filter
          id="filter0_d_2525_4700"
          x="0"
          y="0"
          width="547"
          height="76"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.164706 0 0 0 0 0.435294 0 0 0 0 0.882353 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2525_4700"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2525_4700"
            result="shape"
          />
        </filter>
      </defs>
    </g>,
    <g className={styles.cardInner} key="card5">
      <g className={styles.background} filter="url(#filter0_d_2525_4699)">
        <path d="M10 8C10 4.68629 12.6863 2 16 2H531C534.314 2 537 4.68629 537 8V52C537 55.3137 534.314 58 531 58H16C12.6863 58 10 55.3137 10 52V8Z" />
      </g>
      {icons}
      <path
        d="M30 24.5C30 22.2909 31.7909 20.5 34 20.5H60C62.2091 20.5 64 22.2909 64 24.5V35.5C64 37.7091 62.2091 39.5 60 39.5H34C31.7909 39.5 30 37.7091 30 35.5V24.5Z"
        fill="var(--brand-color-blue-1)"
      />
      <path
        d="M42 28.4492C42 27.6492 41.6 27.3492 40.7 27.3492H39.1L39 27.4492V29.4492L39.1 29.5492H40.7C41.6 29.5492 42 29.2492 42 28.4492ZM43 28.4492C43 29.5492 42.4 30.1492 41.5 30.3492V30.4492L42.9 32.6492V33.3492L42.8 33.4492H42.4L42.2 33.3492L40.4 30.5492L40.2 30.4492H39.1L39 30.5492V33.3492L38.9 33.4492H38.1L38 33.3492V26.5492L38.1 26.4492H40.7C42.1 26.4492 43 27.0492 43 28.4492Z"
        fill="var(--brand-color-blue-7)"
      />
      <path
        d="M46.9035 33.5492H46.8035C45.1035 33.5492 44.4035 32.5492 44.4035 30.9492V26.5492L44.5035 26.4492H45.3035L45.4035 26.5492V30.9492C45.4035 31.9492 45.7035 32.6492 46.8035 32.6492H46.9035C48.0035 32.6492 48.3035 31.9492 48.3035 30.9492V26.5492L48.4035 26.4492H49.2035L49.3035 26.5492V30.9492C49.3035 32.5492 48.6035 33.5492 46.9035 33.5492Z"
        fill="var(--brand-color-blue-7)"
      />
      <path
        d="M55.707 33.3492L55.607 33.4492H54.707L54.507 33.3492L52.107 28.4492H52.007V33.3492L51.907 33.4492H51.107L51.007 33.3492V26.5492L51.107 26.4492H52.007L52.207 26.5492L54.607 31.4492H54.707V26.5492L54.807 26.4492H55.607L55.707 26.5492V33.3492Z"
        fill="var(--brand-color-blue-7)"
      />
      <text
        x="84"
        y="34"
        fill="var(--brand-color-grey-8)"
        fontFamily="var(--font-family-mono)"
        fontSize="12"
      >
        file:3d9897cfe027ecc7cbdb16e74a676ed143725ea2d08dbb0dde…
      </text>
      <defs>
        <filter
          id="filter0_d_2525_4699"
          x="0"
          y="0"
          width="547"
          height="76"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.164706 0 0 0 0 0.435294 0 0 0 0 0.882353 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2525_4699"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2525_4699"
            result="shape"
          />
        </filter>
      </defs>
    </g>,
  ];

  const [state, setState] = useState(
    Array.from({ length: cards.length }, (_, index) => ({
      id: index,
      active: index === 0,
      phase: index === 0 ? 1 : undefined,
    })),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setState((prevState) => {
        const currentActiveIndex = prevState.findIndex((c) => c.active);
        const nextIndex = (currentActiveIndex + 1) % cards.length;

        if (nextIndex === 0) {
          return prevState.map((card, index) => ({
            ...card,
            active: index === 0,
            phase: index === 0 ? 1 : undefined,
          }));
        }

        return prevState.map((card, index) => {
          if (card.active) {
            return {
              ...card,
              active: false,
              phase: card.phase === 2 ? 2 : null,
            };
          } else if (index === nextIndex) {
            return {
              ...card,
              active: true,
              phase: 1,
            };
          }
          return card;
        });
      });
    }, CARD_TIME);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const phaseTimeout = setTimeout(() => {
      setState((prevState) =>
        prevState.map((card) => {
          if (card.active && card.phase !== null && card.phase !== undefined) {
            return {
              ...card,
              phase: (card.phase + 1) % 3,
            };
          }
          return card;
        }),
      );
    }, CARD_TIME / 2);

    return () => clearTimeout(phaseTimeout);
  }, [state]);

  return (
    <Explainer width={587} height={318} className={styles.root}>
      <g className={styles.ui}>
        {cards.map((content, index) => (
          <Card key={index} index={index} cardState={state[index]}>
            {content}
          </Card>
        ))}
      </g>
    </Explainer>
  );
};

export default ContainerRegistry;
