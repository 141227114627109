import React, { useRef } from 'react';
import Explainer from '../blocks';

import * as styles from './PolicyManagement.module.css';

const EXPLAINER_WIDTH = 628;
const EXPLAINER_HEIGHT = 342;

const PolicyManagement = () => {
  const sectionRef = useRef(null);

  return (
    <Explainer
      width={EXPLAINER_WIDTH}
      height={EXPLAINER_HEIGHT}
      className={styles.root}
    >
      <rect
        fill="url(#dot-pattern)"
        stroke="none"
        width="100%"
        height="100%"
        ref={sectionRef}
      />
      <rect
        fill="url(#hide-pattern-gradient)"
        stroke="none"
        width="100%"
        height="100%"
        ref={sectionRef}
      />

      <g className={styles.ui}>
        <rect x="40" y="26" width="81" height="23" rx="4" fill="#314755" />
        <text
          fill="white"
          fontFamily="var(--font-family-mono)"
          fontSize="10"
          letterSpacing="0.04em"
          x="48.1824"
          y="41"
        >
          Conditions
        </text>
        <rect x="40" y="152" width="62" height="23" rx="4" fill="#314755" />
        <text
          fill="white"
          fontFamily="var(--font-family-mono)"
          fontSize="10"
          letterSpacing="0.04em"
          x="48.4377"
          y="167"
        >
          Actions
        </text>
        <path
          data-figma-bg-blur-radius="6"
          d="M40 189C40 185.686 42.6863 183 46 183H268C271.314 183 274 185.686 274 189V244C274 247.314 271.314 250 268 250H46C42.6863 250 40 247.314 40 244V189Z"
          fill="#1C3340"
        />
        <text
          opacity="0.75"
          fill="white"
          fontFamily="var(--font-family-mono)"
          fontSize="9"
          letterSpacing="0.005em"
        >
          <tspan x="64" y="209.15">
            Packages
          </tspan>
        </text>
        <text
          fill="white"
          fontFamily="var(--font-family-body)"
          fontSize="14"
          letterSpacing="0.005em"
        >
          <tspan x="64" y="228.06">
            Quarantine
          </tspan>
        </text>
        <path
          d="M241.022 209.978L242.975 209.98L248.071 215.071L248.072 217.064L242.98 222.163L241.02 222.162L235.923 217.059L235.923 215.09L241.022 209.978Z"
          fill="#1BB694"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M241.922 218.215L244.678 214.678L244.306 214.388L241.691 217.744L241.369 217.744L239.767 215.933L239.414 216.246L241.157 218.215L241.922 218.215Z"
          fill="white"
          stroke="white"
          strokeWidth="0.147344"
        />

        <path
          data-figma-bg-blur-radius="6"
          d="M40 63C40 59.6863 42.6863 57 46 57H268C271.314 57 274 59.6863 274 63V118C274 121.314 271.314 124 268 124H46C42.6863 124 40 121.314 40 118V63Z"
          fill="#1C3340"
        />
        <text
          opacity="0.75"
          fill="white"
          fontFamily="var(--font-family-mono)"
          fontSize="9"
          letterSpacing="0.005em"
          x="64"
          y="83.15"
        >
          Vulnerabitiy
        </text>
        <text
          fill="white"
          fontFamily="var(--font-family-body)"
          fontSize="14"
          letterSpacing="0.005em"
          x="64"
          y="102.06"
        >
          CVE Severity - Critical
        </text>
        <path
          d="M241.022 83.9776L242.975 83.9804L248.071 89.0715L248.072 91.0645L242.98 96.1625L241.02 96.1623L235.923 91.0589L235.923 89.0901L241.022 83.9776Z"
          fill="#1BB694"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M241.922 92.2151L244.678 88.6776L244.306 88.3878L241.691 91.7437L241.369 91.7437L239.767 89.9335L239.414 90.2459L241.157 92.2151L241.922 92.2151Z"
          fill="white"
          stroke="white"
          strokeWidth="0.147344"
        />
        <line
          x1="156.5"
          y1="181"
          x2="156.5"
          y2="123"
          stroke="#498AF6"
          strokeDasharray="2 2"
        />
      </g>
      <g className={styles.code}>
        <path
          d="M326 32C326 28.6863 328.686 26 332 26H1632C1635.31 26 1638 28.6863 1638 32V345H326V32Z"
          fill="#1C3340"
        />
        <text fill="#5B798A">
          <tspan x="344.676" y="53.2">
            1&#10;
          </tspan>
          <tspan x="344.676" y="75.2">
            2&#10;
          </tspan>
          <tspan x="344.676" y="97.2">
            3&#10;
          </tspan>
          <tspan x="344.676" y="119.2">
            4&#10;
          </tspan>
          <tspan x="344.676" y="141.2">
            5&#10;
          </tspan>
          <tspan x="344.676" y="163.2">
            6&#10;
          </tspan>
          <tspan x="344.676" y="185.2">
            7&#10;
          </tspan>
          <tspan x="344.676" y="207.2">
            8&#10;
          </tspan>
          <tspan x="344.676" y="229.2">
            9&#10;
          </tspan>
          <tspan x="337.592" y="251.2">
            10&#10;
          </tspan>
          <tspan x="337.592" y="273.2">
            11&#10;
          </tspan>
          <tspan x="337.592" y="295.2">
            12&#10;
          </tspan>
          <tspan x="337.592" y="317.2">
            13&#10;
          </tspan>
          <tspan x="337.592" y="339.2">
            14&#10;
          </tspan>
        </text>
        <text fill="#C5D4DE">
          <tspan x="429.59" y="53.2">
            &nbsp;policy&#x2028;
          </tspan>
          <tspan x="380" y="75.2">
            &#x2028;
          </tspan>
          <tspan x="422.505" y="97.2">
            &nbsp;rego.v1&#x2028;
          </tspan>
          <tspan x="380" y="119.2">
            &#x2028;
          </tspan>
          <tspan x="571.274" y="141.2">
            &#x2028;
          </tspan>
          <tspan x="380" y="163.2">
            max_cvss :=&nbsp;
          </tspan>
          <tspan x="465.011" y="163.2">
            &#x2028;
          </tspan>
          <tspan x="380" y="185.2">
            &#x2028;
          </tspan>
          <tspan x="826.306" y="207.2">
            &#x2028;
          </tspan>
          <tspan x="380" y="229.2">
            cve_allowlist :=&nbsp;
          </tspan>
          <tspan x="620.863" y="229.2">
            &#x2028;
          </tspan>
          <tspan x="380" y="251.2">
            &#x2028;
          </tspan>
          <tspan x="429.59" y="273.2">
            &nbsp; match :=&nbsp;
          </tspan>
          <tspan x="528.769" y="273.2">
            &#x2028;
          </tspan>
          <tspan x="380" y="295.2">
            &#x2028;
          </tspan>
          <tspan x="380" y="317.2">
            match&nbsp;
          </tspan>

          <tspan x="443.758" y="317.2">
            &#x2028;
          </tspan>
          <tspan x="415.421" y="339.2">
            &nbsp;target&nbsp;
          </tspan>
          <tspan x="486.263" y="339.2">
            &nbsp;input.v0.security_scan&#x2028;
          </tspan>
        </text>
        <text fill="var(--brand-color-purple-6)">
          <tspan x="380" y="53.2">
            package
          </tspan>
        </text>
        <text fill="var(--brand-color-purple-6)">
          <tspan x="380" y="97.2">
            import
          </tspan>
        </text>
        <text fill="var(--brand-color-purple-6)">
          <tspan x="380" y="273.2">
            default
          </tspan>
        </text>
        <text fill="var(--brand-color-purple-6)">
          <tspan x="500.432" y="273.2">
            false
          </tspan>
        </text>
        <text fill="var(--brand-color-purple-6)">
          <tspan x="422.505" y="317.2">
            if
          </tspan>
        </text>
        <text fill="var(--brand-color-purple-6)">
          <tspan x="387.084" y="339.2">
            some
          </tspan>
        </text>
        <text fill="var(--brand-color-purple-6)">
          <tspan x="387.084" y="361.2">
            some
          </tspan>
        </text>
        <text fill="var(--brand-color-purple-6)">
          <tspan x="472.095" y="339.2">
            in
          </tspan>
        </text>
        <text fill="#FFD482">
          <tspan x="380" y="141.2">
            # maximum allowed CVSS score
          </tspan>
        </text>
        <text fill="#FFD482">
          <tspan x="380" y="207.2">
            # array containing IDs of CVEs that have been explicitly allowed
          </tspan>
        </text>
        <text fill="#57C7A4">
          <tspan x="465.011" y="163.2">
            6
          </tspan>
        </text>
        <text fill="#B73B55">
          <tspan x="380" y="559.2">
            &#125;
          </tspan>
        </text>
        <text fill="#B73B55">
          <tspan x="443.758" y="317.2">
            &#123;
          </tspan>
        </text>
        <text fill="#B73B55">
          <tspan x="500.432" y="229.2">
            &#91;
          </tspan>
        </text>
        <text fill="#B73B55">
          <tspan x="620.863" y="229.2">
            &#93;
          </tspan>
        </text>
        <text fill="#1BB694">
          <tspan x="507.516" y="229.2">
            &#34;CVE-2023-32681&#34;
          </tspan>
        </text>
      </g>
      <defs>
        <pattern
          id="dot-pattern"
          viewBox="0 0 10 10"
          width={`${(10 / EXPLAINER_WIDTH) * 100}%`}
          height={`${(10 / EXPLAINER_HEIGHT) * 100}%`}
        >
          <circle
            cx="5"
            cy="5"
            r="1.155"
            fill="var(--brand-color-grey-5)"
            fillOpacity="0.1"
          />
        </pattern>
        <linearGradient
          id="hide-pattern-gradient"
          gradientTransform="rotate(90)"
        >
          <stop offset="0%" stopColor="var(--brand-color-black)" />
          <stop
            offset="20%"
            stopColor="var(--brand-color-black)"
            stopOpacity="0"
          />
        </linearGradient>
      </defs>
    </Explainer>
  );
};

export default PolicyManagement;
