import gsap from 'gsap';
import Explainer from '../blocks';

const EXPLAINER_WIDTH = 411;
const EXPLAINER_HEIGHT = 280;

import styles from './Broadcasts.module.css';
import { useEffect } from 'react';

const Broadcasts = () => {
  useEffect(() => {
    const timeline = gsap.timeline({ repeat: -1 });

    timeline
      .to(`.${styles.circle}`, {
        duration: 2,
        ease: 'power1.inOut',
        scale: 1.5,
        transformOrigin: '50% 50%',
        stagger: 0.1,
      })
      .to(
        `.${styles.circle}`,
        {
          duration: 1,
          ease: 'none',
          opacity: 1,
          stagger: 0.15,
        },
        '<',
      )
      .to(
        `.${styles.circle}`,
        {
          duration: 1,
          ease: 'none',
          opacity: 0,
          stagger: 0.15,
        },
        '<1',
      );
  }, []);

  return (
    <Explainer
      width={EXPLAINER_WIDTH}
      height={EXPLAINER_HEIGHT}
      className={styles.root}
    >
      <g clipPath="url(#clip0_507_10382)">
        <circle cx="205.668" cy="281.5" r="28" className={styles.circle} />
        <circle cx="206.168" cy="281" r="63.5" className={styles.circle} />
        <circle
          opacity="0.7"
          cx="205.668"
          cy="281.5"
          r="99"
          className={styles.circle}
        />
        <circle
          opacity="0.6"
          cx="205.668"
          cy="281.5"
          r="135"
          className={styles.circle}
        />
        <circle
          opacity="0.5"
          cx="206.168"
          cy="281"
          r="170.5"
          className={styles.circle}
        />
        <circle
          opacity="0.3"
          cx="205.668"
          cy="281.5"
          r="206"
          className={styles.circle}
        />
        <circle
          opacity="0.2"
          cx="205.668"
          cy="281.5"
          r="242"
          className={styles.circle}
        />
        <circle
          opacity="0.2"
          cx="205.668"
          cy="281.5"
          r="281"
          className={styles.circle}
        />
        <g filter="url(#filter0_d_507_10382)">
          <path
            d="M81.3334 24C81.3334 20.6863 84.0197 18 87.3334 18H323.997C327.311 18 329.997 20.6863 329.997 24V144.981C329.997 148.295 327.311 150.981 323.997 150.981H87.3334C84.0197 150.981 81.3334 148.295 81.3334 144.981V24Z"
            fill="white"
          />
          <text
            fill="#092F45"
            fontFamily="var(--font-family-body)"
            fontSize="15.1361"
            letterSpacing="0.0075em"
            x="98.6317"
            y="50.4994"
          >
            Platform SDK
          </text>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M108.682 66.4319H106.062L104.975 67.6104L104.975 69.8581H103.745L102.345 71.4004V72.5544V75.3702V76.5241L103.745 78.0664H110.817L112.217 76.5241V75.3702V72.5544V71.4004L110.817 69.8581H106.057V68.0328L106.536 67.513L108.234 67.513L108.925 68.2038L109.69 67.4393L108.682 66.4319ZM103.426 75.3702V72.5544V71.818L104.223 70.9393H110.338L111.136 71.818V72.5544V75.3702V76.1065L110.338 76.9852H104.223L103.426 76.1065V75.3702ZM106.74 72.5544V75.4823H107.821V72.5544H106.74Z"
            fill="#26465B"
          />
          <text
            fill="#26465B"
            fontFamily="var(--font-family-headline)"
            fontSize="12.9738"
            letterSpacing="0.0075em"
            x="120.565"
            y="76.9753"
          >
            Public
          </text>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M187.067 71.5843L183.241 67.7579H181.163L177.337 71.5843V73.6618L181.163 77.4882H183.241L187.067 73.6618V71.5843ZM188.148 74.1097V71.1365L183.689 66.6768H180.716L176.256 71.1365V74.1097L180.716 78.5694H183.689L188.148 74.1097Z"
            fill="#26465B"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M184.141 72.6231L182.901 71.3836L183.666 70.6191L185.287 72.2409V73.0054L183.666 74.6271L182.901 73.8626L184.141 72.6231Z"
            fill="#26465B"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M180.264 72.6231L181.503 71.3836L180.739 70.6191L179.117 72.2409V73.0054L180.739 74.6271L181.503 73.8626L180.264 72.6231Z"
            fill="#26465B"
          />
          <text
            fill="#26465B"
            fontFamily="var(--font-family-headline)"
            fontSize="12.9738"
            letterSpacing="0.0075em"
            x="195.176"
            y="76.9753"
          >
            760
          </text>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M243.583 66.8157V74.4756L240.54 71.4331L239.776 72.1976L243.235 75.6562H245.012L248.471 72.1976L247.707 71.4331L244.664 74.4756V66.8157H243.583ZM240.818 77.4747L238.566 74.9406L237.758 75.659L240.333 78.5559H243.197H245.05H247.914L250.49 75.659L249.682 74.9406L247.429 77.4747H245.05H243.197H240.818Z"
            fill="#26465B"
          />
          <text
            fill="#26465B"
            fontFamily="var(--font-family-headline)"
            fontSize="12.9738"
            letterSpacing="0.0075em"
            x="257.097"
            y="76.9753"
          >
            190K
          </text>
          <g clipPath="url(#clip1_507_10382)">
            <path
              d="M125.938 121.507L98.6317 132.685L94.172 134.182L98.6317 137.675L311.888 138.174L314.726 132.685L316.753 115.12H300.116L281.499 127.096L261.615 109.232L249.642 127.096L240.532 109.731L226.887 127.096L221.508 119.112L208.269 132.685H178.481L168.366 109.731L159.036 121.507L152.002 108.733H142.9L135.867 127.096L125.938 121.507Z"
              fill="#EBF2FC"
              stroke="#2A6FE1"
              strokeWidth="2.1623"
            />
          </g>
        </g>
        <g filter="url(#filter1_d_507_10382)">
          <rect
            x="133.106"
            y="165"
            width="144"
            height="34"
            rx="17"
            fill="white"
            shapeRendering="crispEdges"
          />
          <rect
            x="145.106"
            y="173"
            width="32"
            height="18"
            rx="9"
            fill="white"
          />
          <rect
            x="145.106"
            y="173"
            width="32"
            height="18"
            rx="9"
            fill="#2A6FE1"
            fillOpacity="0.1"
          />
          <rect
            x="145.606"
            y="173.5"
            width="31"
            height="17"
            fill="none"
            rx="8.5"
            stroke="#2A6FE1"
            strokeOpacity="0.1"
          />
          <rect
            x="161.106"
            y="175"
            width="14"
            height="14"
            rx="7"
            fill="#2A6FE1"
          />
          <text
            fill="#2A6FE1"
            fontFamily="var(--font-family-mono)"
            fontSize="13"
            letterSpacing="0.65px"
            x="188.106"
            y="186.55"
          >
            BROADCAST
          </text>
        </g>
      </g>
      <rect
        fill="url(#hide-broadcasts-gradient)"
        stroke="none"
        width="100%"
        height="100%"
      />
      <defs>
        <linearGradient
          id="hide-broadcasts-gradient"
          gradientTransform="rotate(90)"
        >
          <stop offset="0%" stopColor="var(--color-background-default)" />
          <stop
            offset="10%"
            stopColor="var(--color-background-default)"
            stopOpacity="0"
          />
        </linearGradient>
        <filter
          id="filter0_d_507_10382"
          x="61.3334"
          y="13"
          width="288.664"
          height="172.981"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="15" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.286275 0 0 0 0 0.541176 0 0 0 0 0.964706 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_507_10382"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_507_10382"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_507_10382"
          x="118.106"
          y="160"
          width="174"
          height="64"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.286275 0 0 0 0 0.541176 0 0 0 0 0.964706 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_507_10382"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_507_10382"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_507_10382">
          <rect width="411" height="280" fill="white" />
        </clipPath>
        <clipPath id="clip1_507_10382">
          <rect
            width="214.067"
            height="25.9476"
            fill="white"
            transform="translate(98.6317 107.735)"
          />
        </clipPath>
      </defs>
    </Explainer>
  );
};

export default Broadcasts;
